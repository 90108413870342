import Image from "next/image";
import Link from "next/link";
import { FaDesktop } from "react-icons/fa6";
import { IoSchoolOutline } from "react-icons/io5";

export default function Hero() {
  return (
    <div className="pt-20 bg-primary">
      <div className="min-h-[40rem] relative mx-auto max-w-screen-xl">
        <div className="grid grid-cols-1 lg:grid-cols-2 px-4">
          <div className="lg:pr-20 mt-32 px-0 sm:px-16 lg:px-0">
            <h4 className="font-bold text-2xl text-white tracking-[0.72px] text-center lg:text-left">Digitalisasi Sistem Operasional Pendidikan</h4>
            <p className="tracking-wide text-white/60 leading-relaxed mt-2 text-center lg:text-left">Satu Platform Inovatif menawarkan solusi terintegrasi untuk sekolah dan pesantren yang mengelola siswa, keuangan, dan komunikasi dengan orang tua dengan cara yang efisien dan efektif.</p>
            <div className="flex flex-col sm:flex-row gap-2 tracking-wider mt-4 items-center justify-center lg:justify-start">
              <Link
                href="/#features"
                className="btn btn-accent text-white rounded-full w-52 !font-semibold shadow-lg shadow-accent/70"
              >
                Selengkapnya
              </Link>
              <Link
                href="/daftar"
                className="btn bg-tprimary-4 border-tprimary-4 hover:bg-tprimary-4/90 hover:border-tprimary-4/90 text-white rounded-full w-52 !font-semibold"
              >
                <FaDesktop className="w-5 h-5" />
                Jadwalkan Demo
              </Link>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 py-8 items-center justify-center lg:justify-start">
              <div className="flex items-end gap-2">
                <div className="w-12 h-12 bg-tsecondary-2 rounded-lg shadow-lg shadow-tsecondary-2/70 text-white flex justify-center items-center">
                  <IoSchoolOutline className="w-6 h-6" />
                </div>
                <div className="flex flex-col">
                    <span className="font-medium tracking-widest text-sm text-white/80">TOTAL SEKOLAH</span>
                    <span className="font-black text-white/80">359</span>
                </div>
              </div>
              <div className="flex items-end gap-2">
                <div className="w-12 h-12 bg-taccent-3 rounded-lg shadow-lg shadow-taccent-3/70 text-white flex justify-center items-center">
                  <IoSchoolOutline className="w-6 h-6" />
                </div>
                <div className="flex flex-col">
                    <span className="font-medium tracking-widest text-sm text-white/80">PENGGUNA CARDS</span>
                    <span className="font-black text-white/80">18,975</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <Image loading="eager" src="/image/heroimageraw.png" width={500} height={500} className="block lg:hidden z-10" alt="hero image" />
          </div>
        </div>
        <Image loading="eager" src="/image/heroimageraw.png" width={620} height={500} className="hidden lg:block absolute bottom-5 right-0 xl:right-10" alt="hero image" />
      </div>

      <div className="relative">
        <Image loading="eager" src="/image/shape.svg" width={500} height={500} alt="shape" className="absolute w-full -bottom-1 z-50" />
      </div>
    </div>
  );
}
