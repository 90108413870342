export default function getTestimonials(index) {
    let testi1 = [
        {
            name: 'Lembaga',
            avatar: '/image/placeholder/avatar1.webp',
            description: 'Cards telah membantu kami mengoptimalkan pengelolaan keuangan sekolah. Dengan fitur-fitur seperti pembayaran online dan laporan keuangan yang terperinci, kami dapat mengelola keuangan dengan lebih efisien.',
            school: 'SDN 1 Kartika Jaya'
        },
        {
            name: 'Lembaga',
            avatar: '/image/placeholder/avatar1.webp',
            description: 'Kami sangat terbantu dengan layanan pelanggan Cards. Tim dukungan mereka responsif dan membantu kami menyelesaikan setiap masalah dengan cepat. Kami merasa didukung sepanjang waktu.',
            school: 'SMP Nurul Iman'
        },
        {
            name: 'Bu Dian',
            avatar: '/image/placeholder/avatar1.webp',
            description: 'Aplikasi Orang Tua Cards memberi saya kesempatan untuk berkomunikasi langsung dengan orang tua siswa. Ini membantu saya membangun hubungan yang lebih baik dengan mereka dan meningkatkan dukungan terhadap pembelajaran anak-anak.',
            school: 'Guru Matematika SMP Bina Bangsa'
        },
        {
            name: 'Pak Agus',
            avatar: '/image/placeholder/avatar1.webp',
            description: 'Kartu Siswa Digital sangat membantu dalam mengelola absensi dan perizinan siswa. Saya dapat dengan mudah melacak kehadiran siswa dan mengelola perizinan melalui aplikasi, menghemat waktu dan tenaga.',
            school: 'Wali Kelas SMA Mutiara Bakti'
        },
    ];

    let testi2 = [
        {
            name: 'Ibu Ani',
            avatar: '/image/placeholder/avatar1.webp',
            description: 'Aplikasi Orang Tua Cards memudahkan saya untuk memantau progres akademik anak saya. Saya bisa melihat nilai, absensi, dan tagihan sekolah dengan mudah, tanpa harus menunggu laporan dari sekolah.',
            school: 'Orang Tua Siswa SD Juara'
        },
        {
            name: 'Bapak Rudi',
            avatar: '/image/placeholder/avatar1.webp',
            description: 'Saya merasa lebih tenang mengetahui anak saya bisa bertransaksi di kantin sekolah menggunakan Kartu Siswa Digital. Ini memberikan keamanan dan mengajarkan mereka tentang pengelolaan keuangan.',
            school: 'Orang Tua Siswa SMP Persada'
        },
        {
            name: 'Ibu Siti Rahayu',
            avatar: '/image/placeholder/avatar1.webp',
            description: 'Cards telah mengubah cara kami mengelola sekolah. Dengan fitur-fitur yang terintegrasi, administrasi menjadi lebih mudah dan efisien, memungkinkan kami untuk fokus pada pengembangan siswa.',
            school: 'Kepala Sekolah SMP Harapan Bangsa'
        },
        {
            name: 'Bapak Ahmad Ibrahim',
            avatar: '/image/placeholder/avatar1.webp',
            description: 'Saya sangat terkesan dengan kemampuan Dashboard Cards. Saya dapat melihat progres siswa secara langsung, memantau kehadiran, dan mengelola keuangan lembaga dengan mudah.',
            school: 'Kepala Sekolah SMA Cendekia Mandiri'
        },
        
    ];

    return [testi1, testi2][index];
}