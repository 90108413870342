import Link from "next/link";
import { FaDesktop, FaWhatsapp } from "react-icons/fa6";

export default function CallToAction() {
  return (
    <section className="text-dark-muted mx-auto max-w-screen-2xl px-4 pt-16">
      <div className="text-center">
        <h1 className="text-2xl font-bold tracking-wide">Tunggu Apa Lagi?</h1>
        <div className="flex justify-center">
          <p className="text-opacity-80 tracking-tight sm:max-w-md">
            Kami siap membantu Anda menemukan
            solusi terbaik untuk lembaga pendidikan Anda.
          </p>
        </div>
        <div className="flex sm:flex-row flex-col justify-center items-center gap-2 mt-4">
          <Link
            href="/"
            className="btn btn-primary text-white rounded-full w-52 !font-semibold shadow-lg shadow-tprimary-3/70"
          >
            <FaWhatsapp className="w-5 h-5" />
            Kontak Whatsapp
          </Link>
          <Link
            href="/daftar"
            className="btn btn-accent text-white rounded-full w-52 !font-semibold shadow-lg shadow-accent/70"
          >
            <FaDesktop className="w-5 h-5" />
            Request Demo
          </Link>
        </div>
      </div>
    </section>
  );
}
