import SectionMark from "../Misc/SectionMark";
import { CgQr } from "react-icons/cg";
import { TiDocumentText } from "react-icons/ti";
import { IoMdFolder } from "react-icons/io";
import Image from "next/image";
import { FaDesktop } from "react-icons/fa6";
import { GrSecure } from "react-icons/gr";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";

export default function Features() {
  return (
    <div className="mx-auto max-w-screen-xl px-4 pt-16">
      <div className="px-4 md:px-8 lg:px-16">
        <div className="w-full bg-[#DDE5FF] py-8 rounded-3xl">
          <Swiper
            slidesPerView={1}
            grabCursor={true}
            spaceBetween={30}
            freeMode={true}
            loop={true}
            autoplay={{ delay: 0 }}
            speed={18000}
            direction="horizontal"
            breakpoints={{
              0: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 5,
              },
              1024: {
                slidesPerView: 7,
              },
            }}
            modules={[FreeMode, Autoplay]}
            className="mix-blend-luminosity opacity-60"
          >
            {[...Array(10)].map((x, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <img
                    src="/image/placeholder/tutwuri.png"
                    alt="school image"
                    className="w-28"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <seection id="features" className="py-32 grid grid-cols-1 md:grid-cols-2 text-dark-muted">
        <div className="md:mr-24">
          <SectionMark>Apa Itu Cards?</SectionMark>
          <h2 className="text-2xl font-bold tracking-wide">
            Sulitnya mengelola Operasional Manual? Temukan Solusi dengan{" "}
            <span className="text-primary">Cards</span>.
          </h2>
          <p className="text-opacity-80 tracking-tight mt-4">
            Cards menyediakan fitur-fitur kombinasi akademik dan keuangan yang
            terintegrasi. Cards menyediakan solusi lengkap untuk mengelola
            berbagai aspek penting dalam lingkungan pendidikan.
          </p>
          <div className="flex flex-col sm:flex-row gap-2 tracking-wider mt-4 items-center justify-center lg:justify-start">
            <Link
              href="/partner/sekolah"
              className="btn btn-primary text-white rounded-full w-56 !font-semibold"
            >
              Pelajari Untuk Sekolah
            </Link>
            <Link
              href="/partner/pesantren"
              className="btn btn-accent text-white rounded-full w-56 !font-semibold"
            >
              Pelajari Untuk Pesantren
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-1 min-[402px]:grid-cols-2 gap-x-4 md:gap-x-2 gap-y-8 mt-8 md:mt-0">
          <div>
            <div className="w-12 h-12 bg-primary rounded-lg text-white flex justify-center items-center">
              <FaDesktop className="w-6 h-6" />
            </div>
            <h5 className="font-semibold mt-1">Mendigitalkan Administrasi</h5>
            <p className="text-sm tracking-tight text-opacity-80">
              Mengelola administrasi otomatis dan tersistem. Membantu lembaga
              menyediakan laporan akurat, cepat dan efisien.
            </p>
          </div>
          <div>
            <div className="w-12 h-12 bg-primary rounded-lg text-white flex justify-center items-center">
              <CgQr className="w-6 h-6" />
            </div>
            <h5 className="font-semibold mt-1">Transaksi Menjadi Digital</h5>
            <p className="text-sm tracking-tight text-opacity-80">
              Kemudahan pembayaran tagihan dengan banyak metode pembayaran
              (online), transaksi dikantin yang aman dengan kartu siswa digital.
            </p>
          </div>
          <div>
            <div className="w-12 h-12 bg-primary rounded-lg text-white flex justify-center items-center">
              <TiDocumentText className="w-6 h-6" />
            </div>
            <h5 className="font-semibold mt-1">Akses mudah dan transparan</h5>
            <p className="text-sm tracking-tight text-opacity-80">
              Memudahkan pemantauan progres akademik serta keuangan siswa oleh
              orang tua dan lembaga pendidikan melalui Mobile App dan dashboard.
            </p>
          </div>
          <div>
            <div className="w-12 h-12 bg-primary rounded-lg text-white flex justify-center items-center">
              <GrSecure className="w-6 h-6" />
            </div>
            <h5 className="font-semibold mt-1">Keamanan Terjamin</h5>
            <p className="text-sm tracking-tight text-opacity-80">
              Mengutamakan keamanan dengan menerapkan enkripsi dan hak akses
              yang terkontrol untuk melindungi informasi sensitif. Lembaga juga
              dapat mengatur hak akses sesuai kewenangan.
            </p>
          </div>
        </div>
      </seection>
    </div>
  );
}
