import Image from "next/image";
import SectionMark from "../Misc/SectionMark";
import { FaCircleCheck, FaDesktop, FaWhatsapp } from "react-icons/fa6";
import { FiArrowRightCircle } from "react-icons/fi";
import Link from "next/link";

export default function DetailedFeatures() {
  return (
    <section className="text-dark-muted mx-auto max-w-screen-xl px-4 pt-16">
      <div className="text-center">
        <h1 className="text-2xl font-bold tracking-wide">
          Temukan keandalan layanan dengan <span className="text-primary">Cards</span>?
        </h1>
        <div className="flex justify-center">
          <p className="text-opacity-80 tracking-tight max-w-xl">
            Cards adalah Sistem terintegrasi yang memudahkan operasional lembaga pendidikan, baik sekolah maupun pesantren.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 py-20 items-center">
        <div className="ml-0 md:ml-10">
          <Image width={550} height={500} src="/image/kartusiswa.png" alt="kartu siswa image" />
        </div>
        <div className="md:pl-8 md:mt-0 mt-16">
          <SectionMark>Efisien</SectionMark>
          <h1 className="font-bold tracking-wide text-2xl">Kartu Siswa Digital</h1>
          <p className="text-opacity-80 tracking-tight">
            Satu Kartu, Serba Guna.
          </p>
          <ul className="list-image-[url(check.svg)] list-inside mt-4 text-opacity-80 text-xs min-[424px]:text-sm md:text-base space-y-4">
            <li>
              <span><span className="font-bold">Perizinan Siswa:</span> Dapat digunakan untuk memperoleh izin masuk atau keluar sekolah secara elektronik.</span>
            </li>
            <li>
              <span><span className="font-bold">Identitas Siswa:</span> Sebagai kartu siswa dengan identitas digital yang unik untuk setiap siswa di lembaga pendidikan. Desain suka-suka lembaga.</span>
            </li>
            <li>
              <span><span className="font-bold">Absensi Otomatis:</span> Digunakan untuk melakukan absensi siswa secara otomatis melalui sistem pembacaan QR Code. Rekap otomatis dan terpantau orang tua</span>
            </li>
            <li>
              <span><span className="font-bold">Akses ke Layanan Lembaga:</span> Memberikan akses kepada siswa untuk berbagai layanan dan fasilitas di lembaga pendidikan, seperti perpustakaan, kegiatan ekstrakurikuler, Kesehatan, tabungan dan Pembayaran tagihan sekolah.</span>
            </li>
            <li>
              <span><span className="font-bold">Transaksi Dikantin:</span> Memungkinkan siswa untuk melakukan transaksi non-tunai di kantin sekolah menggunakan saldo yang terdapat dalam kartu siswa. Dilengkapi keamanan PIN transaksi. Disediakan Aplikasi POS dikantin secara gratis.</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 py-20">
        <div className="">
          <SectionMark>Orang Tua Gak Risau</SectionMark>
          <h1 className="font-bold tracking-wide text-2xl">
            Aplikasi Orang Tua
          </h1>
          <p className="text-opacity-80 tracking-tight">
            Terhubung real-time 24/7 antara orang tua, lembaga dan siswa.
          </p>
          <ul className="list-image-[url(check.svg)] list-inside mt-4 text-opacity-80 text-xs min-[424px]:text-sm md:text-base space-y-4">
            <li>
              <span><span className="font-bold">Pemantauan Progres Siswa:</span> Memungkinkan orang tua untuk memantau progres akademik dan kehadiran siswa secara real-time.</span>
            </li>
            <li>
              <span><span className="font-bold">Pembayaran Tagihan:</span> Memberikan akses untuk melihat dan membayar tagihan pendidikan dan kegiatan sekolah secara online.</span>
            </li>
            <li>
              <span><span className="font-bold">Pemantau Uang Saku Siswa:</span> Memberikan akses orang tua untuk mengirim uang saku & monitoring penggunaan uang saku, melimitasi penggunaan harian.</span>
            </li>
            <li>
              <span><span className="font-bold">Pemberitahuan Dan Informasi:</span> Memberikan pemberitahuan tentang pengumuman dan kegiatan sekolah serta menyediakan informasi penting tentang acara-acara mendatang dan berita lembaga.</span>
            </li>
            <li>
              <span><span className="font-bold">Keterlibatan Orang Tau Meningkat:</span> Memungkinkan orang tua untuk terlibat aktif dalam pendidikan anak mereka dengan memberikan akses langsung ke informasi penting.</span>
            </li>
          </ul>
        </div>
        <div className="flex justify-center ml-0 md:ml-20 md:mt-0 mt-16">
          <Image width={350} height={500} src="/image/mobile.png" alt="mobile image" />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 py-20 items-center">
        <div className="ml-10">
          <Image width={550} height={500} src="/image/dashboard.png" alt="dashboard image" />
        </div>
        <div className="md:pl-8 md:mt-0 mt-16">
          <SectionMark>Kemudahan</SectionMark>
          <h1 className="font-bold tracking-wide text-2xl">Aplikasi Lembaga</h1>
          <p className="text-opacity-80 tracking-tight">
            Satu dashboard untuk mengelola kegiatan akademik maupun keuangan
          </p>
          <ul className="list-image-[url(check.svg)] list-inside mt-4 text-opacity-80 text-xs min-[424px]:text-sm md:text-base space-y-4">
            <li>
              <span><span className="font-bold">Manajemen Siswa & Staff:</span> Sebagai master data, mengelola data siswa, alumni, staff dan guru. Termasuk pendaftaran, informasi kontak, dan catatan akademik.</span>
            </li>
            <li>
              <span><span className="font-bold">Manajemen Akademik:</span> Mempermudah guru dalam update progres pembelajaran, kehadiran, rapor dan konseling.</span>
            </li>
            <li>
              <span><span className="font-bold">Pembuatan Laporan:</span> Menyediakan fitur untuk membuat berbagai jenis laporan, seperti laporan kehadiran, laporan pembayaran, laporan keuangan dan laporan akademik.</span>
            </li>
            <li>
              <span><span className="font-bold">Komunikasi Internal:</span> Memfasilitasi komunikasi antara staf pendidikan, termasuk pengiriman pengumuman, pesan, dan tugas.</span>
            </li>
            <li>
              <span><span className="font-bold">Integrasi dengan Sistem Keuangan:</span> Terhubung dengan sistem keuangan lembaga untuk mengelola pembayaran tagihan, transaksi siswa, dan laporan keuangan.</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="w-full bg-tsecondary-3 p-8 rounded-xl flex flex-col md:flex-row justify-between items-center">
        <div className="md:max-w-lg text-center md:text-left">
            <h1 className="text-white font-extrabold text-2xl">Kami siap membantu Anda.</h1>
            <p className="text-white font-light tracking-light">Diskusikan Bagaimana Cards Dapat Membantu Meningkatkan Efisiensi dan Kualitas Layanan Pendidikan Anda!</p>
        </div>
        <div className="mt-4 md:mt-0 flex gap-2 flex-col sm:flex-row">
            <Link href="/" className="btn bg-tsecondary-4 border-tsecondary-4 hover:bg-tsecondary-4/90 hover:border-tsecondary-4/90 text-white rounded-full w-48 !font-semibold">
                Kontak Whatsapp
                <FaWhatsapp className="w-5 h-5" />
            </Link>
            <Link href="/daftar" className="btn bg-tsecondary-4 border-tsecondary-4 hover:bg-tsecondary-4/90 hover:border-tsecondary-4/90 text-white rounded-full w-48 !font-semibold">
                Jadwalkan Demo
                <FaDesktop className="w-5 h-5" />
            </Link>
        </div>
      </div>
    </section>
  );
}
